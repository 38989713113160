import React from 'react';
import { withPrefix } from 'gatsby';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="container p-6 text-center">
          <h1 className="m-0 mb-3">Sorry, we couldn&apos;t find this page.</h1>
          <img className="w-100" src={withPrefix("/heroes/404.svg")} alt="This page cannot be found" />
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
